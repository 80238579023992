.bar-chart-container {
    padding: 16px;
    background-color: #f5f5f5;
    border-radius: 8px;
  
    .apexcharts-bar-series {
      .apexcharts-bar-area {
        &:nth-child(1) {
          fill: #1E90FF;  // Current data color
        }
  
        &:nth-child(2) {
          fill: #b0b0b0;  // Previous data color
        }
      }
    }
  
    .apexcharts-tooltip {
      background-color: #333;
      color: #fff;
      border-radius: 4px;
      padding: 8px;
    }
  }
  