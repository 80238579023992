
.Custom-table-wrapper2{
    border-radius: var(--borderRadius, 8px);
    background: var(--surface-secondary, #F6F8F8);
    .MuiTableRow-root {
        border-bottom: none;
    }

    .MuiTableCell-root {
        border-bottom: none;
        padding: 0;
        margin: 0 8px;
        min-width: 108.8px;
        text-align: left;
    }     
}


.tableCellHeader {
    padding: 0;
    font-size: 0.75rem !important;
    max-width: 46px;
  }
  

.ellipsisText {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 320px; 
    min-width: 220px;
    padding: 0;
    text-align: left;
}

