.chart-container {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.chart-legend {
  margin-bottom: 20px;
  display: flex;
  justify-content: space-around;
  text-align: center;

  .legend-item {
    color: #333;
    font-weight: bold;
  }
}

.metrics-summary {
  margin-top: 20px;
  text-align: center;

  .green-text {
    color: #28a745;
  }

  .red-text {
    color: #dc3545;
  }
}

.custom-legend {
  display: flex;
  align-items: flex-start;
  gap: var(--1, 8px);
}

.legend-item {
  display: flex;
  padding: var(--1, 8px) 12px;
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;
  border-radius: var(--borderRadius-small, 4px);
  border: 1px solid var(--divider, rgba(0, 0, 0, 0.12));
  min-width: 140px;

  @media screen and (max-width: 360px) {
    padding: 12px 6px;
    min-width: 90px;
  }
}

.legend-item input[type="checkbox"] {
  appearance: none;
  width: 16px;
  height: 16px;
  margin-right: 8px;
  border: 2px solid #ccc;
  border-radius: 3px;
  cursor: pointer;
  position: relative;
}

.legend-item input[type="checkbox"]:checked {
  background-color: currentColor;
  border-color: currentColor;
}

/* Add checkmark when checked */
.legend-item input[type="checkbox"]:checked::after {
  content: "";
  position: absolute;
  left: 4px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.legend-label {
  color: var(--text-primary, rgba(0, 0, 0, 0.87));
  font-feature-settings: "liga" off, "clig" off;
  font-family: var(--fontFamily, "IBM Plex Sans");
  font-size: var(--font-size-075-rem, 12px);
  font-style: normal;
  font-weight: var(--fontWeightMedium, 500);
  line-height: 20px;
  letter-spacing: 0.14px;
}
