@use '../../sass/global' as g;
@use '../../sass/util' as u;

.navbar {
    display: flex;
    padding: 0.5rem 1.5rem;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    // background-color: g.$HeaderAndFooterBackgroundColor;
    flex-grow: 1;

    .navbar-icons {
      display: flex;
      align-items: center;
      gap: 1rem;
    }   
  }
  
  