@use '../../../sass/global' as g;
@use '../../../sass/util' as u;

.search-and-filter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: u.rem(16);
}

.charts-container {
  display: grid;
  gap: u.rem(16);
  padding: u.rem(16);
  grid-template-columns: repeat(2, 1fr);

  @include u.breakpoint-down(small){
    grid-template-columns: repeat(1, 1fr);
  }
}

.card-container {
  .business-metrics {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .card-content {
    display: flex;
    flex-direction: column;
    padding: u.rem(16);

    .profile-title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: u.rem(16);

      .btnStyle {
        background: none;
        border: none;
        cursor: pointer;

        .btnContainer {
          display: flex;
          align-items: center;

          h3 {
            margin-right: u.rem(8);
          }

          svg {
            font-size:  u.rem(14);
          }
        }
      }

      .totals {
        display: flex;
        align-items: center;

        p {
          margin: 0 u.rem(8);;
          display: flex;
          align-items: center;

          .icon-clicks,
          .icon-impressions {
            margin-right: u.rem(4);
            font-size: u.rem(20);
          }
        }
      }
    }

    .line-chart {
      width: 100%;
      height: 300px;
    }
  }
}

  

  @include u.breakpoint-down(medium){
    .search-and-filter {
      flex-direction: column;
      row-gap: u.rem(16);
      align-items:center;
      justify-content: flex-start;
    }
  }

  
  