// Dashboard.scss

@use '../../sass/global' as g;
@use '../../sass/util' as u;
@use '../../sass/global/colors' as *;

.dashboard {
  background-color: $sidebar-bg-color;
  color: #fff !important;
  width: 100%;
  padding: 24px 5rem;
  display: flex;
  flex-direction: column;

  &-logo-placeholder{
    display: flex;
    width: 32px;
    height: 32px;
    padding: 1rem;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    border-radius: var(--borderRadius-small, 0.25rem);
    background: var(--surface-tertiary, #e2e6e9);
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    background-color: #f5f5f5;
  
    h1 {
      font-size: 1.5rem;
    }
  
    span {
      color: #888;
    }
  }
  

  .dashboard-tabs {
    border-bottom: 1px solid #888;
    margin-top: 1rem;
    flex-grow: 1;
    .tab{
      font-size:14px;
      font-weight: 500 !important;
      text-transform: uppercase;
      color: #FFFFFF80 !important;
      
    }
  }

  .tab-content {
    margin-top: 16px;
  }

  .tab-contents{
    margin-top: 2rem;
  }

  .card{
    width: 100%;
    height: 250px;
    height: 100%;
    border-radius: 4px;
    padding: 16px;
  }

  .cardContent{
    display: flex;
    justify-content: space-between;

    flex-wrap: wrap;
  }


  @media screen and (max-width: 880px) {
      padding: 24px 1rem;
  }
}
