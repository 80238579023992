@use 'functions' as f;

$breakpoints-up: (
    "small": f.em(373),
    "medium": f.em(700),
    "large": f.em(900),
    "xlarge": f.em(1440)
);

// 699.98px, 899.98px, 1439.98px
$breakpoints-down: (
    'xxsmall': f.em(319.98),
    'xsmall': f.em(489.98),
    'small': f.em(699.98),
    "medium": f.em(899.98),
    "large": f.em(1439.98)
);

@mixin breakpoint($size) {
    @media (min-width: map-get($breakpoints-up, $size)) {
        @content;
    }
}

@mixin breakpoint-down($size) {
    @media (max-width: map-get($breakpoints-down, $size)) {
        @content;
    }
}