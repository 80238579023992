.website-performance-card {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 108px;
    padding: 3px 20px 3px 14px;
    margin-bottom: 16px;
    border-radius: 8px;
    background-color: #fff;
   

  .card-title {
    font-weight: bold;
    margin-bottom: 8px;
  }


  @media screen and (max-width: 880px) {
    
      width: 100%;
    
  }
  
}

.chart-container {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  }
  
  .chart-legend {
    margin-bottom: 20px;
    display: flex;
    justify-content: space-around;
    text-align: center;
  
    .legend-item {
      color: #333;
      font-weight: bold;
    }
  }
  
  .metrics-summary {
    margin-top: 20px;
    text-align: center;
  
    .green-text {
      color: #28a745;
    }
  
    .red-text {
      color: #dc3545;
    }
  }
  

  .custom-legend {
    display: flex;
    gap: 10px;
    margin-bottom: 10px;
  }

  .legend-item{
    border: 1px solid #e1e2e3;
    border-radius: 5px;
    padding: 12px 1rem;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    // height: 62px;
    // width: auto;
    min-width: 140px;
  }

  .legend-item input[type="checkbox"] {
    appearance: none;
    width: 16px;
    height: 16px;
    margin-right: 8px;
    border: 2px solid #ccc;
    border-radius: 3px;
    cursor: pointer;
    position: relative;
  }
  
  .legend-item input[type="checkbox"]:checked {
    background-color: currentColor;
    border-color: currentColor;
  }
  
  /* Add checkmark when checked */
  .legend-item input[type="checkbox"]:checked::after {
    content: '';
    position: absolute;
    left: 4px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
  }

  .legend-label{
    color: #000000;
    font-weight: '500';
    // font-size: 12px;
    margin-top: -5px;
  }
  