.Custom-table-wrapper{
  border-radius: var(--borderRadius, 8px);
  background: var(--surface-secondary, #F6F8F8);
  .MuiTableRow-root {
      border-bottom: none;
  }

  .MuiTableCell-root {
      border-bottom: none;
      padding: 0;
      margin: 0 8px;
  }     

  .tableCellHeader {
      padding: 0 0 6px 0;
      font-size: 14px;
      text-align: center;
    }

    .tableCell{
      // max-width: "65px";
      text-align: "center" 
    }

    .ellipsisText {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 160px; /* Adjust width as needed */
      min-width: 160px;
    }
}