@use '../../sass/global/colors' as *;


.edit-profile {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 20px;
    padding: 24px 5rem;

  
    .section {
      display: flex;
      flex-direction: column;
      gap: 15px;
    }
  
    .business-info {
      display: flex;
      flex-direction: column;
      gap: 15px;
      // width: 488px;
  
      .logo-section {
        display: flex;
        align-items: center;
        gap: 10px;
      }
    }
  
    .locations {
      display: flex;
      flex-direction: column;
      gap: 10px;
  
      .location-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 0;
        // border-bottom: 1px solid #e0e0e0;
  
        .location-details {
          display: flex;
          align-items: center;
          gap: 15px;
        }
  
        .actions {
          display: flex;
          gap: 5px;

          .icon{
            color: $sidebar-bg-color;
          }
        }
      }
  
      .add-location-button{
        display: flex;
        justify-content: flex-start;
        column-gap: 12px;
      }
      .add-location {
        margin-top: 10px;
        
        // width: 140px;
      }
    }
  }
  