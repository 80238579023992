@use '../../sass/global/colors' as *;


.Add-Campaign-Container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    // gap: 20px;
    padding: 0 72px;

    @media screen and (max-width: 500px) {
      padding: 0 26px;

    }

    @media screen and (max-width: 390px) {
      padding: 0 16px;

    }
  }
  