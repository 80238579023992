.review-email-edit-screen-container{
    width: 100%;
    padding: 0 4.5rem;

    .phone-frame {
        width: 240px;
        height: 480px;
        border: 8px solid rgba(0, 0, 0, 0.5);
        border-radius: 16px;
        // display: flex;
        // justify-content: flex-end;
        // align-items: flex-start;
        background-color: #fff;
        position: relative;

        .phone-frame-title{
            background-color: rgba(236, 239, 241, 1);
            width: 100%;
            padding: 8px 16px; 
            font-size: 12px;
            font-family: 'IBM Plex Sans';
            font-weight: '400';
            border-radius: 8px 8px 0 0;

        }

        .phone-frame-content{
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            padding: 32px 16px;
            font-weight: 400;


            .title{
                text-align: center;
            }

            .content{
                
                color: rgba(0, 0, 0, 0.87);
                font-size: 12px;
                font-family: 'IBM Plex Sans';
                font-weight: 400;
                letter-spacing: 0.17;
                word-wrap: 'break-word'
            }
            
            .greetings{
                padding: 20px 0;
            }
        }

        .submit-btn{
            text-align: center;
            position: absolute;
            top: 385px;
            left: 50%;
            transform: translateX(-50%);
            width: 100%;
        }
    }
    .sms-message {
        background-color: rgba(69, 90, 100, 1);
        color: #fff;
        border-radius: 16px 16px 0 16px;
        max-width: 160px;
        height: 450px;
        overflow-y: auto;
        width: 160px;
        // height: 50px;
        padding: var(--1, 8px) var(--2, 16px);
        font-size: 0.75rem;
        word-wrap: break-word;
    }

    @media screen and (max-width: 600px) {
        padding: 0 1.5rem;
        
    }
}
