@use "../../../sass/global" as g;
@use "../../../sass/util" as u;

.business-settings-container {
  display: flex;
  padding-bottom: 5rem;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--6, 3rem);
  flex: 1 0 0;
  align-self: stretch;
  margin: auto 6.75rem;

  @media screen and (max-width: 812px) {
    margin: auto 3.75rem;
    
  }

  @media screen and (max-width: 400px) {
    margin: auto 1.75rem;
    
  }

  .business-settings-header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--1, 0.5rem);
    align-self: stretch;
    .business-settings-header-title {
      color: var(--Schemes-On-Surface, #171d1a);
      text-align: center;
      font-feature-settings: "liga" off, "clig" off;
    }
    .business-settings-tab-container {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      align-self: stretch;
      border-bottom: 1px solid
        var(--primary-_states-outlinedBorder, rgba(51, 132, 132, 0.5));

      .css-rga5dg-MuiButtonBase-root-MuiTab-root {
        min-height: auto !important;
      }

      .business-settings-tab-container-tab {
        display: flex;
        flex-direction: row;
        padding: 9px 16px;
        justify-content: center;
        align-items: center;
        text-transform: none;
        gap: var(--1, 0.5rem);

        @media screen and (max-width: 400px) {
          padding: 9px 8px;
          
        }
      }
    }
  }

  .business-settings-form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--3, 1.5rem);
    align-self: stretch;
    border-radius: var(--borderRadius-large, 1rem);
    &-title {
      display: flex;
      align-items: center;
      gap: var(--2, 1rem);
      &-logo {
        display: flex;
        width: 2.5rem;
        height: 2.5rem;
        padding: 1rem;
        justify-content: center;
        align-items: center;
        gap: 0.5rem;
        border-radius: var(--borderRadius-small, 0.25rem);
        background: var(--surface-tertiary, #e2e6e9);
      }
      &-text {
        color: var(--text-primary, rgba(0, 0, 0, 0.87));
        font-feature-settings: "liga" off, "clig" off;
      }
    }
    &-profile {
      display: flex;
      align-items: flex-start;
      flex-wrap: wrap;
      gap: 3rem;
      align-self: stretch;
      &-left {
        display: flex;
        max-width: 32rem;
        flex-direction: column;
        align-items: flex-start;
        gap: var(--2, 1rem);
        flex: 1 0 0;
        border-radius: var(--borderRadius-large, 1rem);
        &-text {
          color: var(--text-primary, rgba(0, 0, 0, 0.87));
          font-feature-settings: "liga" off, "clig" off;
        }
        &-title {
          color: var(--text-primary, rgba(0, 0, 0, 0.87));
          font-feature-settings: "liga" off, "clig" off;
        }
        &-agencyName,
        &-agencyWebsite,
        &-agencyEmailAddress {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          align-self: stretch;
        }
        &-upload-control {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: var(--2, 1rem);
          align-self: stretch;
          &-container {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            column-gap: u.rem(16);
            &-image-source {
              display: flex;
              padding: 1rem;
              width: u.rem(100);
              height: u.rem(100);
              align-items: center;
              gap: 0.5rem;
              border-radius: var(--borderRadius, 0.5rem);
              background: var(--surface-tertiary, #e2e6e9);
            }
          }
        }
      }
      &-right {
        display: flex;
        max-width: 32rem;
        flex-direction: column;
        align-items: flex-start;
        gap: var(--2, 1rem);
        flex: 1 0 0;
        border-radius: var(--borderRadius-large, 1rem);
        &-text {
          color: var(--text-primary, rgba(0, 0, 0, 0.87));
          font-feature-settings: "liga" off, "clig" off;
        }
        &-container {
          display: flex;
          padding: var(--6, 3rem);
          flex-direction: column;
          align-items: flex-start;
          gap: var(--3, 1.5rem);
          align-self: stretch;
          border-radius: var(--borderRadius, 0.5rem);
          background: var(--surface-primary, #eceff1);
          &-top {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 1rem;
            align-self: stretch;
          }
          &-middle {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 0.5rem;
            align-self: stretch;
            &-text {
              color: var(--text-primary, rgba(0, 0, 0, 0.87));
              text-align: center;
              font-feature-settings: "liga" off, "clig" off;
              align-self: stretch;
            }
          }
          &-bottom {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: var(--2, 1rem);
            align-self: stretch;
          }
        }
      }
    }

    &-billing-header {
      display: flex;
      width: 32rem;
      flex-direction: column;
      align-items: flex-start;
      gap: 1rem;
      &-title {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 1rem;
        align-self: stretch;
      }
      &-body {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 0.5rem;
      }
    }
    &-billing-body {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 1rem;
      &-main {
        display: flex;
        width: 32rem;
        padding: 1rem 1.5rem 1.5rem 1.5rem;
        flex-direction: column;
        align-items: flex-start;
        gap: 0.5rem;
        border-radius: var(--borderRadius, 0.5rem);
        background: var(--surface-primary, #eceff1);
        &-content {
          display: flex;
          justify-content: space-between;
          align-items: center;
          align-self: stretch;
          &-left {
            display: flex;
            align-items: center;
            gap: 1rem;
            &-rectangle {
              width: var(--borderRadius-round, 7.5rem);
              height: 4.5rem;
              border-radius: var(--borderRadius-small, 0.25rem);
              background: var(--surface-secondary, #f6f8f8);
            }
            &-body {
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: flex-start;
              gap: 0.375rem;
              &-bottom {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap: 0.125rem;
                &-body {
                  display: flex;
                  align-items: flex-start;
                  gap: var(--borderRadius-small, 0.25rem);
                }
              }
            }
          }
          &-right {
            display: flex;
            align-items: flex-start;
            gap: var(--1, 0.5rem);
            .edit-button,
            .delete-button {
              display: flex;
              padding: 0.3125rem;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              border-radius: 6.25rem;
            }
          }
        }
      }
    }
    &-billing-line,
    &-team-line {
      width: 32rem;
      height: 0.0625rem;
      background: var(--divider, rgba(0, 0, 0, 0.12));
    }

    &-team-header {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 0.5rem;
      align-self: stretch;
    }
    &-team-body {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 1rem;
      align-self: stretch;
      &-title {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 1rem;
        align-self: stretch;
      }
      &-members-group {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: var(--2, 1rem);
        align-self: stretch;
        &-member {
          display: flex;
          justify-content: space-between;
          align-items: center;
          align-self: stretch;
          &-logo {
            display: flex;
            align-items: center;
            gap: var(--1, 0.5rem);
            &-person {
              display: flex;
              width: var(--4, 2rem);
              flex-direction: column;
              justify-content: center;
              align-items: center;
              border-radius: var(--borderRadius-round, 7.5rem);
              background: lightgray 50% / cover no-repeat,
                var(--primary-main, #0f4343);
            }
            &-name {
              display: flex;
              width: 6.4375rem;
              flex-direction: column;
              align-items: flex-start;
              word-break: break-word;
              white-space: normal;
            }
          }
          &-control {
            display: flex;
            align-items: center;
          }
          &-icon {
            display: flex;
            align-items: flex-start;
            gap: var(--1, 0.5rem);
          }
        }
      }
    }
    &-team-invite-members {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 1rem;
      align-self: stretch;
      &-label {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 1rem;
        align-self: stretch;
      }
      &-body {
        display: flex;
        align-items: center;
        gap: var(--2, 1rem);
        align-self: stretch;
      }
      &-controls {
        display: flex;
        align-items: center;
        gap: 1rem;
        align-self: stretch;
      }
    }
  }




}
  /* BusinessSelector.scss */

 .businessConnect-container {
  padding: 2rem;
  background-color: #f9f9f9;
  border-radius: 8px;
}

.businessConnect-title {
  font-weight: 600;
  margin-bottom: 1.5rem;
  text-align: center;
  color: #333;
}

.business-content {
  max-height: 80vh;
  min-width: 800px;
  overflow-y: auto;
  margin: 1.5rem 0rem;
  padding-right: 1rem;

  .group-title {
    font-size: 1.2rem;
    color: #555;
    margin-bottom: 0.8rem;
  }
}

.businessGroup {
  margin-bottom: 2rem;
  /* Responsive grid for businesses */
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr)); /* Two businesses per row for larger screens */
  gap: 1rem;

  @media (max-width: 768px) {
    grid-template-columns: 1fr; /* One business per row for mobile */
  }

  .checkboxContainer {
    display: flex;
    align-items: center;
    padding: 0.5rem;
    border-radius: 4px;
    transition: background-color 0.2s ease;
    background-color: #fff; /* Ensure background color for better alignment visuals */

    &:hover {
      background-color: #f1f1f1;
    }

    .businessName {
      margin-left: 0.8rem;
      font-size: 1rem;
      color: #333;
      flex-grow: 1; /* Ensures text stays left-aligned even in varying widths */
    }

    .MuiCheckbox-root {
      flex-shrink: 0; /* Prevent checkbox from resizing */
    }
  }
}

.footer-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #ddd;
  padding-top: 1rem;

  .selectAll {
    display: flex;
    align-items: center;

    .MuiTypography-root {
      margin-left: 0.5rem;
      color: #555;
    }
  }

  .MuiButton-containedPrimary {
    background-color: #007bff;
    &:hover {
      background-color: #0056b3;
    }
  }
}

  

  .connectDialog{
    .css-1kxd7on-MuiDialogContent-root{
      padding: 0 !important;
    }
  }