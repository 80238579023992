@use '../../sass/global' as g;
@use '../../sass/util' as u;

.review-list-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: u.rem(16);
}

.review-carousel {
  margin: 0;
  height: u.rem(50);
  color: #fff;
  max-width: u.rem(600);
  line-height: u.rem(40);
  text-align: center;
  background: #364d79;
  margin: u.rem(16);

  .carousel-slide {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    h3 {
      margin: 0;
    }
  }
}

.review-list-card {
  margin-bottom: u.rem(16);
  width: 100%; 

  .ant-card-meta-avatar {
    margin-right: u.rem(16);
  }
  
  .ant-card-meta-title {
    font-weight: bold;
    font-size: 1.1em;
  }

  .review-comment {
    margin-top: u.rem(10);
  }
  .response-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;
  
    label {
      margin-bottom: 8px;
      font-weight: bold;
    }
  
    .ant-input-textarea {
      margin-bottom: 16px;
    }
  
    .button-group {
      display: flex;
      gap: 10px;
      margin-top: 10px;
    }
    @include u.breakpoint-down(small) {
      .button-group {
        flex-direction: column;
      }
    }
  }  

}

.load-more-button-container {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: u.rem(16) 0;
}
.card-header {
  display: flex;
  justify-content: flex-end;
  margin: 12px 0;
}

@include u.breakpoint-down(small) {
  .review-carousel {
    max-width: u.rem(300);
  }
}

@include u.breakpoint-down(xsmall) {
  .review-carousel {
    max-width: u.rem(250);
  }
}
