.stats-card {
  display: flex;
  padding: var(--2, 16px) var(--2, 16px) var(--3, 24px) var(--2, 16px);
  flex-direction: column;
  align-items: flex-start;
  gap: var(--1, 8px);
  flex: 1 0 0;
  border-radius: var(--borderRadius, 8px);
  background: var(--surface-secondary, #f6f8f8);
  height: 151px;

  .card-value {
    display: flex;
    align-items: flex-start;
    justify-content: baseline;
    gap: 4px;
  }

  h3 {
    font-size: 2rem;
    margin-bottom: 0.5rem;
  }

  h5 {
    font-size: 1rem;
    margin-bottom: 1rem;
  }
}
