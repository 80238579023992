@use '../../sass/global/colors' as *;


.profile-container {
    width: 100%;
    padding: 24px 5rem;
    display: flex;
    flex-direction: column;
  
    .profile-header {
      display: flex;
      justify-content: space-between;
      margin-bottom: 16px;
  
      .profile-info {
        display: flex;
        align-items: center;
        gap: 1rem;
  
        .profile-logo {
          width: 36px;
          min-width: 36px;
          height: 36px;
          min-height: 36px;
          background-color: #f6f5f5;
          border: 1px solid #e0e0e0;
          border-radius: 6px;
        }
  
        .profile-text {
          h5 {
            margin: 0;
            font-weight: bold;
          }
  
          p {
            margin: 4px 0;
            color: #757575;
          }
        }
      }
  
      .edit-profile-button {
        background-color: $sidebar-bg-color;
        color: white;
      }

      @media screen and (max-width: 500px) {
        flex-wrap: wrap;
        row-gap: 1;
      }
    }
  
    .profile-tabs {
      border-bottom: 1px solid #e0e0e0;

      .tab{
        font-size:14px;
        font-weight: bold !important;
        text-transform: capitalize;
      }
    }
  
    .tab-content {
      margin-top: 16px;
    }
  
    .business-info-card {
        display: flex;
        flex-direction: column;
        width: 76%;
        gap: 24px;
        padding: 24px;
        margin-bottom: 16px;
        border-radius: 8px;
        background-color: #f9f9f9;
    
      .card-title {
        font-weight: bold;
        margin-bottom: 8px;
      }
  
      .edit-icon {
        float: right;
        color: $sidebar-bg-color;
        cursor: pointer;
      }

      @media screen and (max-width: 880px) {
        
          width: 100%;
        
      }
      
    }
    

    @media screen and (max-width: 880px) {
        padding: 24px 1rem;
    }

  }
  