@use "../../../sass/global" as g;
@use "../../../sass/util" as u;
.modal-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1300;
  .business-modal {
    display: flex;
    width: 45rem;
    padding: var(--4, 2rem);
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: var(--4, 2rem);
    flex-shrink: 0;
    border-radius: 1rem;
    background: var(--surface-secondary, #f6f8f8);
    &-header {
      display: flex;
      width: 41rem;
      justify-content: space-between;
      align-items: flex-start;
    }
    &-body {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 1rem;
      align-self: stretch;
      &-card-details {
        display: flex;
        align-items: flex-start;
        gap: 1rem;
        align-self: stretch;
      }
      &-checkbox {
        display: flex;
        align-items: center;
      }
      &-controls {
        display: flex;
        align-items: center;
        gap: var(--2, 1rem);
        align-self: stretch;
      }

     
    }

    .business-modal-line{
      width: 100%;
      height: 1px;
      background-color:  rgba(0, 0, 0, 0.12);;
    }
  }
}
