.sms-preview-main {
  border-radius: 8px;
  width: 250px;
  height: 480px;
  border: 8px solid rgba(0, 0, 0, 0.5);
  border-radius: 16px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  background-color: #fff;
  padding: 24px 24px 0 24px;
  overflow-y: auto;
}
