.sidebar-menus {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
  align-self: stretch;
  
  .business-profiles {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 0.5rem;
    align-self: stretch;

    .MuiButton-sizeMedium.MuiButton-containedPrimary{
      text-align: left;
    }
  } 
}



.active-menu-item {
  cursor: pointer;
  padding-left: 2.45rem;
  min-height: 30px;
  width: 100%;
  display: flex;
  align-items: center;
  
  &:hover {
    background-color: #4F6666;
  }

  &.active {
    background-color: #4F6666;
  }
}
