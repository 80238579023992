@use '../../sass/global/colors' as *;

.data-archives-container {
    // width: 650px;
    padding: 20px;
    overflow-x: auto;
    margin-top: -20px;
      
    .business-section {
      margin-bottom: 30px;  
      // padding: 0 10px;
      border: 1px solid #ccc;
      border-radius: 12px;
      position: relative;
                                              
      .business-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;
        background-color: $sidebar-bg-color;
        color: $white;
        padding: 0 12px 10px;
        border-radius: 12px 12px 0 0;

        
        .view-all-button {
          cursor: pointer;
          color: blue;
          text-decoration: underline;
        }
      }
  
      .document-list {
        display: flex;
        // justify-content: center;
        overflow-x: auto;
        scroll-behavior: smooth;
        padding: 8px 20px;

         // Hide the scrollbar visually but keep it accessible
          scrollbar-width: none; /* For Firefox */
          -ms-overflow-style: none;  /* For Internet Explorer and Edge */
          
          &::-webkit-scrollbar {
            display: none; /* For Chrome, Safari, and Opera */
          }
        
        .document-item {
          max-width: 110px;
          min-width: 110px;
          height: 140px;
          margin-right: 10px;
          padding: 6px;
          border: 1px solid #ddd;
          cursor: pointer;
          text-align: center;
          
          img {
            width: 75px;
            max-width: 75px;
            height: auto;
          }


          p {
            margin-top: 8px;
            font-size: 14px;
          }
        
          &:hover {
            transform: scale(1.05);
        
            .hover-overlay {
              display: flex;
            }
          }
        
          .hover-overlay {
            display: none;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, 0.6);
            justify-content: center;
            align-items: center;
            color: white;
            font-size: 16px;
            cursor: pointer;
        
            span {
              text-decoration: underline;
            }
          }
        }
      }
  
      .document-search {
        text-align: right;
        margin-top: 10px;
        color: $white;

        .css-aksybn-MuiButtonBase-root-MuiButton-root.MuiButton-sizeMedium.MuiButton-outlinedPrimary:not(.Mui-disabled):not(.Mui-focusVisible) {
          color: #fbfbfb;
          border: 1px solid rgb(198 233 233 / 50%);
          background: none;
        }
      }


      .scroll-button {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        z-index: 10;
        background-color: rgba(0, 0, 0, 0.5);
        color: white;
        border: none;
        border-radius: 50%;
        cursor: pointer;
        padding: 4px 8px;
    
        &.left {
          left: 0;
          margin-left: -20px;
        
        }
    
        &.right {
          right: 0;
          margin-right: -20px;
        }

        .arrow{
          margin-top: 4px;
          font-size: 22px;
        }
      }

    }
  }
  