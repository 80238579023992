@use "../../../sass/global" as g;
@use "../../../sass/util" as u;
.modal-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw; 
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(
    0,
    0,
    0,
    0.5
  );
  z-index: 1300;
  .common-modal {
    display: flex;
    width: 45rem;
    padding: var(--4, 2rem);
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: var(--4, 2rem);
    flex-shrink: 0;
    border-radius: 1rem;
    background: var(--surface-secondary, #f6f8f8);
    &-header {
        display: flex;
        width: 41rem;
        justify-content: space-between;
        align-items: flex-start;
    }
    &-content {
      display: flex;
      padding: var(--2, 1rem);
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: var(--borderRadius-small, 0.25rem);
      align-self: stretch;
      border-radius: var(--borderRadius, 0.5rem);
      background: var(--red-50, #fcedf0);
    }
    &-actions {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      gap: var(--2, 1rem);
      align-self: stretch;
    }
  }
}
