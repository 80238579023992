@use '../../sass/global' as g;
@use '../../sass/util' as u;

.graph-toggles {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: u.rem(8);
  
    .icons {
      display: flex;
      justify-content: center;
      align-items: center;
      gap:  u.rem(16);
  
      .toggle-icon {
        font-size: u.rem(20);
        cursor: pointer;
        transition: color 0.3s, background-color 0.3s;
        padding: u.rem(4);
        border-radius: u.rem(4);
  
        &.active {
          color: #fff !important;
          background-color: g.$primary;
        }
      }
    }
  
    .date {
      display: flex;
      justify-content: center;
    }
}

.toggle-icon {
    cursor: pointer;
    color: #ccc;
    background-color: #fff;
}
  
.toggle-icon.active {
    color: #fff;
    background-color: g.$primary;
}
  
@include u.breakpoint-down(medium) {
    .graph-toggles {
      .icons {
        justify-content: space-between;
      }
  
      .date {
        justify-content: flex-end;
      }
    }
}
  
@include u.breakpoint-down(small) {
    .graph-toggles {
      grid-template-columns: 1fr;
      row-gap: u.rem(16);
  
      .icons {
        justify-content:flex-start;
      }
  
      .date {
        justify-content:flex-start;
      }
    }
}
