// total background styles
.webscrap-container {
  // background: linear-gradient(to right, #0f2027, #203a43, #2c5364);

  max-width: 100%;
  min-width: 80%;
  margin: 0 auto;
  padding: 2rem;
  text-align: center;
}
// total background styles

/* export btn styles  */

.export_btn {
  /* position: relative; */
  /* display: inline-block; */
  background: linear-gradient(to bottom, #102229, #2b5262);
  /* Gradient background */
  color: white;
  /* White text color */
  font-family: "Segoe UI", sans-serif;
  /* Stylish and legible font */
  font-weight: bold;
  /* font-size: 10px; */
  /* Large font size */
  border: none;
  /* No border */
  border-radius: 10px;
  /* Rounded corners */
  padding: 8px 15px;
  /* Large padding */
  cursor: pointer;
  /* Cursor on hover */
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  /* Subtle shadow */
  animation: button-shimmer 2s infinite;
  transition: all 0.3s ease-in-out;
  /* Smooth transition */
}

/* Hover animation */
.export_btn:hover {
  background: linear-gradient(to bottom, #102229, #102229);
  animation: button-particles 1s ease-in-out infinite;
  transform: translateY(-2px);
}

/* Click animation */
.export_btn:active {
  transform: scale(0.95);
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
}
/* export btn styles  */

/* checkbox styles  */

.custom-checkbox {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
  font-size: 16px;
  color: #000;
  transition: color 0.3s;
}

.custom-checkbox input[type="checkbox"] {
  display: none;
}

.custom-checkbox .checkmark {
  width: 24px;
  height: 24px;
  border: 2px solid #000;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  transition: background-color 0.3s, border-color 0.3s, transform 0.3s;
  transform-style: preserve-3d;
}

.custom-checkbox .checkmark::before {
  content: "\2713";
  font-size: 16px;
  color: transparent;
  transition: color 0.3s, transform 0.3s;
}

.custom-checkbox input[type="checkbox"]:checked + .checkmark {
  background-color: #0f2027;
  border-color: #333;
  transform: scale(1.1) rotateZ(360deg) rotateY(360deg);
}

.custom-checkbox input[type="checkbox"]:checked + .checkmark::before {
  color: #fff;
}

.custom-checkbox:hover {
  color: #666;
}

.custom-checkbox:hover .checkmark {
  border-color: #666;
  background-color: #f0f0f0;
  transform: scale(1.05);
}

.custom-checkbox input[type="checkbox"]:focus + .checkmark {
  box-shadow: 0 0 3px 2px rgba(0, 0, 0, 0.2);
  outline: none;
}

.custom-checkbox .checkmark,
.custom-checkbox input[type="checkbox"]:checked + .checkmark {
  transition: background-color 1.3s, border-color 1.3s, color 1.3s,
    transform 0.3s;
}

/* checkbox styles  */

/* btn styles  */

.webscraping-button {
  align-items: center;
  color: white;
  background: linear-gradient(to bottom, #102229, #2b5262);
  /* border: 1px solid rgba(0, 0, 0, 0.1); */
  border-radius: 5px;
  border: none;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
  /* color: rgba(0, 0, 0, 0.85); */
  cursor: pointer;
  display: inline-flex;
  font-family: system-ui, -apple-system, system-ui, "Helvetica Neue", Helvetica,
    Arial, sans-serif;
  font-size: 16px;
  font-weight: 600;
  justify-content: center;
  line-height: 1.25;
  min-height: 3rem;
  padding: calc(0.875rem - 1px) calc(1.5rem - 1px);
  text-decoration: none;
  transition: all 250ms;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: baseline;
  width: auto;
}

.webscraping-button:hover,
.webscraping-button:focus {
  background: linear-gradient(to bottom, #102229, #102229);
  animation: button-particles 1s ease-in-out infinite;
  transform: translateY(-2px);
}

.webscraping-button:hover {
  transform: translateY(-1px);
}

.webscraping-button:active {
  background-color: #f0f0f1;
  border-color: rgba(0, 0, 0, 0.15);
  box-shadow: rgba(0, 0, 0, 0.06) 0 2px 4px;
  color: rgba(0, 0, 0, 0.65);
  transform: translateY(0);
}
