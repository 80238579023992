@use '../../sass/global/colors' as *;


// SCSS styles for MassicChatView
.chatView-container{
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;

  .dataArchives-container{
    flex-grow: 1;
    margin-left: 1rem;
  }

  @media screen and (max-width: 720px) {
    flex-wrap: wrap;
    flex-direction: column-reverse;
  }
}

.chat-content {
  position: relative;
  right: 0;
  // top: 0;
  // z-index: 1000;
  display: flex;
  align-items: center;
  // margin-top: -25px;
    
    .toggleButton {
      // position: fixed;
      display: flex;
      align-items: center;
      cursor: pointer;
      background-color: $sidebar-bg-color;
      color: white;
      // padding: 10px;
      height: 3.5rem;
      width: auto;
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
      text-align: center;
      // margin-right: 5px;
      transition: all 0.3s ease;
    
      .arrowIcon{
        font-size: 1.6rem;
      }
    }

    .chatContainer {
      display: flex;
      flex-direction: column;
      width: 380px;
      height: 85vh;
      border: 1px solid #ccc;
      border-radius: 10px;
      box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
      // background-color: #f3f3f3;


    .settings-chat{
      display: flex;
      justify-content: center;
      background-color: #fff;
      border-radius: 22px;
      .tabContent{
        position: absolute;
        background-color: #fff;
        
        
      }
    }
    }
    
}
.buttonGroup{
  display: flex;
  justify-content: center;
  margin-top: 1rem;
  gap: 8px;
}

.header {
  padding: 16px 0;  // Default to 16px if no height is passed
  text-align: center;
  color: white;
  background: linear-gradient(97deg, $sidebar-bg-color 0%, $sidebar-bg-color 100%);
}

.headerTitle {
  margin: 0;
  font-size: 1.2rem;
}

.toolbar {
  display: flex;
  justify-content: space-around;
  background-color: #f1f1f1;
  padding: 10px;
  border-bottom: 1px solid #ccc;
}

.toolbarButton {
  padding: 5px 10px;
  color: #5e5e5e;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  box-shadow: 3px 0px 13px -5px rgba(158, 158, 158, 1);
}

.chatBox {
  flex: 1;
  padding: 10px;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  gap: 10px;
  background-color: #f3f3f3;
}

.userMessage {
  align-self: flex-end;
  background-color: $sidebar-bg-color;
  color: white;
  padding: 10px;
  border-radius: 10px;
  max-width: 60%;
}

.botMessage {
  align-self: flex-start;
  background-color: #e5e5ea;
  color: black;
  padding: 10px;
  border-radius: 10px;
  max-width: 60%;
}

.loading {
  text-align: center;
  padding: 20px;
}

.errorMessage {
  color: red;
  text-align: center;
}

.errorIcon {
  font-size: 1.5rem;
  margin-right: 10px;
}

.formContainer {
  display: flex;
  padding: 10px;
}

.inputContainer {
  border: 1px solid #ccc;
  border-radius: 10px;
  background-color: #fff;
  margin: 0 5px 8px;
}

.inputField {
  flex: 1;
  border: none;
  border-radius: 10px;
  outline: none;
  resize: none;
}

.sendButton {
  padding: 7px 14px;
  background-color: $sidebar-bg-color;
  color: white;
  border: none;
  border-radius: 10px;
  margin-left: 10px;
  cursor: pointer;
}

.ButtonContainer {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.sendButton .icon {
  font-size: 20px;
  color: white;
}

.sendButton .icon2 {
  font-size: 20px;
  color: white;
}

.doneIcon {
  font-size: 12px !important;
  margin-left: 5px;
  color: #fff;
}

.fileInput {
  display: none; // Hide the file input
}

.attachIcon {
  cursor: pointer;
  margin-left: 0;
  position: absolute;
  bottom: 35px;
  left: 90px;
}

.previewImage {
  width: 80px;
  max-height: 80px;
  object-fit: contain;
  border-radius: 10px;
  border: 1px solid #ccc;
  padding: 2px;
  margin: 10px;
}

.clearPreviewButton {
  cursor: pointer;
  color: grey;
  margin-left: 10px;
  position: absolute;
  right: 294px;
  top: 12px;
  font-size: 12px !important;
}


.toolbarButton {
  &:hover {
    background: linear-gradient(97deg, $sidebar-bg-color 0%, $sidebar-bg-color 100%);
    color: $white !important;
  }
}

.typing {
  position: relative;

  span {
    animation: blink 1.5s infinite;
    animation-fill-mode: both;
    height: 10px;
    width: 10px;
    background: $primary; // Use primary theme color
    position: absolute;
    left: 0;
    top: 0;
    border-radius: 50%;

    &:nth-child(2) {
      animation-delay: 0.2s;
      margin-left: 15px;
    }

    &:nth-child(3) {
      animation-delay: 0.4s;
      margin-left: 30px;
    }
  }
}

@keyframes blink {
  0% {
    opacity: 0.1;
  }
  20% {
    opacity: 1;
  }
  100% {
    opacity: 0.1;
  }
}

.imageContainer {
  position: relative;
  margin-right: 10px;
  margin-bottom: 10px;

  img {
    width: 80px;
    height: 80px;
    object-fit: cover;
    border: 1px solid $primary-light-grey;
    border-radius: 8px;
    transition: filter 0.3s ease;
    margin: 8px;
  }

  &:hover img {
    filter: brightness(35%);
  }

  .remove-button {
    position: absolute;
    top: 5px;
    right: 5px;
    background-color: $black;
    color: $white;
    border: none;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    opacity: 0;
    transition: opacity 0.3s ease;
  }

  &:hover .remove-button {
    opacity: 1;
  }
}



