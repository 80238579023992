@use './colors' as c;
@use './fonts' as f;
@use '../util' as u;

@font-face {
  font-family: 'IBM Plex Sans', 'IBM Plex Serif', sans-serif;
  src: url("https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@400;500;600;700&family=IBM+Plex+Serif:wght@400;500;600;700&display=swap") format("truetype");
}

*,*::after,*::before{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
html {
  // font-size: 62.5%;
  font-size: 100%;
  font-family: f.$font1;
  scroll-behavior: smooth;
}
img{
  width: 100%;
}
li{
  list-style-type: none;
}
a{
  text-decoration: none;
  color: c.$white;
}
button{
  background: none;
  font-family: inherit;
  border: none;
}
a, li{
  font-family: inherit;
}
body{
  background-color: c.$white;
  overflow-x: hidden;
}
.button-active{
  background-color: c.$primary;
  color: c.$white;
}
.sectionContainer{
  width: min(100% - u.rem(20), u.rem(1366));
  margin-inline: auto;
}
select{
  width: 100%;
  border: none;
  background-color: #ffffff;
  color: rgba(0, 0, 0, 0.88);
  height: 32.5px;
  border: 1px solid #d9d9d9;
  transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  border-radius: 0.2rem;
  padding-left: 8px;
  padding-right: 8px;
  font-size: u.rem(14);
  font-family: inherit;
  &:focus{
    border-color: #061178;
    box-shadow: 0 0 0 2px rgba(14, 96, 255, 0.17);
    outline: none;
  }
}

.labelStyle{
  font-family: f.$font1;
  font-weight: 500;
  font-size: u.rem(15);
  @include u.breakpoint-down(medium){
    font-size: u.rem(14);
  }
  @include u.breakpoint-down(small){
    font-size: u.rem(13);
  }
  @include u.breakpoint-down(xsmall){
    font-size: u.rem(12);
  }
}

.dashboard-title{
  font-size: u.rem(18);
  font-weight: 600;
  text-transform: uppercase;
  color: c.$light-primary;
  padding: u.rem(8) u.rem(20);
}
.ant-col.ant-form-item-label {
  padding-bottom: u.rem(2) !important; 
}
:where(.css-dev-only-do-not-override-1tjrs0h).ant-empty-normal {
  margin-block: 0px;
}

:where(.css-lsjwgy).ant-modal .ant-modal-content {
  padding: 0px !important;
}

.file-container.dragActive {
  background-color: c.$light-primary-bg;
}
.ant-menu-submenu-popup {
  max-height: 400px;
  overflow-y: auto;
}
// .ant-menu-item-selected {
//   background-color: c.$primary !important; /* Change to your desired color */
//   color: c.$white !important;
// }

// Modal reset
.ant-modal,
.ant-modal-content {
  padding: 0 !important;
}
.ant-modal-header {
  padding: 20px 30px !important;
}
.ant-modal-footer button {
  border-top: 1px solid #f9f9f9;
  margin-right: 10px !important;
}
// Scrollbar
::-webkit-scrollbar{
  width:8px;
}
::-webkit-scrollbar-track {
  background-color: #ebebeb;
}
::-webkit-scrollbar-thumb {
  background-color: #c8c8c8;
  border-radius: 10px;
}