@use '../../../sass/global/colors' as *;


.fileupload-container {
  display: flex;
  justify-content: center;
  margin-top: 3rem;
}

.upload-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 2px dashed $sidebar-bg-color;
  height: 290px;
  width: 450px;
  padding: 0 5rem;
  cursor: pointer;
  border-radius: 5px;


  input {
    display: none; // Hide the file input field
  }

  p{
    font-size: 12px;
    margin-top: 1rem;
  }

  .preview-txt{
    color: $sidebar-bg-color;
    font-weight: bold;
    text-decoration: underline;
    cursor: pointer;
  }

  .sucessIcon{
    font-size: 70px;
    color: $sidebar-bg-color;
  }
}

.uploaded-row {
  width: 100%;
  margin: 10px 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 15px 20px;
  border-radius: 6px;
  background-color: $sidebar-bg-color;
  color: $white;
}

.upload-content {
  display: flex;
  align-items: center;
}


.example {
  color: rgb(75, 75, 75);
  font-family: bold;
  font-size: 12px;
}


.uploadIcon{
  color: $sidebar-bg-color;
}

.preview-txt{
  color: $white;
  cursor: pointer;
}

.placeholder-img{
  width: 80px;
  height: 80px;
}

.upload-btn{
  margin-top: 1rem;
  background-color: $sidebar-bg-color;
  color: $white;
  height: 38px;
  width: 90px;
  border-radius: 8px;
  cursor: pointer;
}

@media screen and (max-width: 560px) {
  .upload-form {
    width: 100%;
    padding: 0 6rem;
  }

}
@media screen and (max-width: 420px) {
  .upload-form {
    padding: 0 4rem;
    text-align: center;
  }

}