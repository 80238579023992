@use '../../../sass/global' as g;
@use '../../../sass/util' as u;
  
  .business-user-login-container {
    background-color: g.$light-primary-bg;
    padding: u.rem(60) u.rem(20);
    border-radius: u.rem(10);
    width: u.rem(380);
    margin-inline: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: u.rem(22);
  
    @include u.breakpoint-down(small){
      width: 300px;
    }
  
    @include u.breakpoint-down(xsmall){
      width: 250px;
    }
  
    button{
      @include u.primaryBtn(g.$primary, g.$white, g.$primary);
      margin-top: u.rem(10);
      padding-top: u.rem(14);
      padding-bottom: u.rem(14);
      width: 100%;
      text-transform: uppercase;
      &:hover{
        background-color: transparent;
        color: g.$primary;
      }
    }
  }
  