@use '../../sass/global' as g;
@use '../../sass/util' as u;

.container-ga4-specific-profile {
  padding: u.rem(10);
  background-color: white;

  .date-btn-specific-profile-ga4{
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include u.breakpoint-down(small) {
      flex-direction: column;
      justify-content: flex-start;
      row-gap: u.rem(10);
    }

    .back-button-ga4-specific-profile{
      border: none;
      margin: u.rem(5);
      font-size: u.rem(18);
    }
  }
 
  .table-container-specific-profile-ga4{
    margin-top: u.rem(16);
    width: 100%;
    .ga4-specific-profile-title{
       color: g.$primary;
       font-size: u.rem(20);
       width: 100%;
       margin: u.rem(10);
       text-align: center;
    }
  }
}