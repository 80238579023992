$primary: #0B0951;
$light-primary: #3A386D;
$light-primary-bg: #eeeeff;
$primary-light-grey: #646378;
$header: #EEEEFF;
$light-grey: #B3BBC5;
$dark-grey: #2F2F30;
$black: #1A1A1A;
$white: #F9FCFF;
$danger: #E95959;
$success: #28A543;


$HeaderAndFooterBackgroundColor: white;
$sidebar-bg-color: #093535;
$menu-item-color: white;
$menu-item-hover-bg: rgba(255, 255, 255, 0.2);
$menu-item-selected-bg: blue;

$created: #2C5CB9;
$inProgress: #B59C1C;
$dcGenerated: #7C2CAD;
$cancelled: #B51A2C;
$delivered: #11A90E;

// Card
 $cardBgHover: #f0f0f0;
 $cardHoverIcon: #0056b3;
 $cardHoverTitle: #0056b3;
 $cardHoverDesc: #333; 
 $cardIcon: #08c;
 $cardTitle: #000;
 $cardDesc: #0056b3;

 // Chart
 $chartContainer: #f0f0f0;
 $chartBackground: #fff;