.Toastify__toast {
  font-family: 'Montserrat' !important;
  font-style: normal !important;
  font-weight: 400 !important;
  margin-top: 2rem;
  font-size: 16px !important;
  line-height: 30px !important;
  width: 745px;
}

.toast_message_success {
  border: 1px solid #3c9f53;
}
.toast_message_error {
  border: 1px solid #d84656;
}
.toast_message_warning{
  border: 1px solid #c9d846;
}
.toast_message_info{
  border: 1px solid #467ed8;
}
.toast_message_network_disconnected {
  border: 1px solid #d84656;
  text-align: center;
  font-size: 14px;
  width: fit-content;
}

.toast_message_network_connected {
  border: 1px solid #3c9f53;
  text-align: center;
  font-size: 14px;
  width: fit-content;
}

/* .toast_message_success::after, .toast_message_warning::after, .toast_message_error::after {
  content: url('../assets/images/close.svg');
  position: absolute;
  right: 11.5px;
  top: 18px;
} */


.Toastify__toast-body > div:last-child {
  padding-left: 10px;
}

.Toastify__close-button > svg {
  display: none;
}

.Toastify__toast-container {
  width: unset;
}

@media screen and (max-width: 1024px) {
  .Toastify__toast {
      width: 600px;
      padding-right: 42px;
  }
}

@media screen and (max-width: 720px) {
  .Toastify__toast {
      width: 450px;
      padding-right: 42px;
  }
}

@media screen and (max-width: 560px) {
  .Toastify__toast {
      width: 400px;
      height: unset;
  }
}

@media screen and (max-width: 480px) {
  .Toastify__toast {
      width: 360px;
  }

  .Toastify__toast-container {
      top: 1em;
      left: 50%;
      transform: translateX(-50%);
  }
}

@media screen and (max-width: 390px) {
  .Toastify__toast {
      width: 320px;
      padding-right: 8px;
  }

  .Toastify__toast-body > div:last-child {
      font-size: small;
  }
}
