.tab-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  padding: 32px 56px 0px 56px;
  background: linear-gradient(
    170deg,
    #093535 1.89%,
    #0d5252 47.47%,
    #093535 99.62%
  );

  .menu-icon {
    display: none;
    color: white;
  }

  .tab {
    text-transform: none !important;
    color: var(--primary-contrastText, #fff) !important;
    opacity: 1 !important;
    font-feature-settings: "liga" off, "clig" off !important;
    font-family: var(--fontFamily, "IBM Plex Sans") !important;
    font-size: var(--font-size-09375-rem, 15px) !important;
    font-style: normal !important;
    font-weight: var(--fontWeightMedium, 500) !important;
    line-height: 26px !important;
    letter-spacing: 0.46px !important;
  }
  .google-button {
    border-radius: var(--borderRadius, 4px) !important;
    background: var(--primary-contrastText, #fff) !important;
    display: flex !important;
    padding: 8px 24px 8px 18px !important;
    justify-content: center !important;
    align-items: center !important;
    gap: 8px !important;
  }

  @media (max-width: 768px) {
    .menu-icon {
      display: block;
    }

    .tabs-wrapper {
      position: relative;
      left: 0;
      width: 100%;
      z-index: 1;
      background: linear-gradient(
        170deg,
        #093535 1.89%,
        #0d5252 47.47%,
        #093535 99.62%
      );
      display: none;
      flex-direction: column;
      align-items: flex-start;
      padding: 10px 10px;

      .tab {
        font-size: 1rem;
        width: 100%;
        text-align: center;
        padding: 12px 0;
      }
    }

    .tabs-wrapper.open {
      display: flex;
    }
  }
}

.homepage-login {
  display: flex;
  width: 100%;
  padding: 32px 56px 0px 56px;
  flex-direction: column;
  align-items: center;
  gap: 108px;
  min-height: 100vh;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  background: linear-gradient(
    170deg,
    #093535 1.89%,
    #0d5252 47.47%,
    #093535 99.62%
  );

  .google-search-console{
    .review-info-card {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;
      row-gap: 1rem !important;
      width: 100%;
      height: 108px;
      padding: 3px 20px 3px 14px;
      border-radius: 8px;
      opacity: 0.2;
  
      @media screen and (max-width: 450px) {
        padding: 1rem 10px;
        height: auto;
      }
  
      .card-title {
        font-weight: bold;
        margin-bottom: 8px;
      }
  
      .toggleButtonGroup {
        display: flex;
        align-items: flex-start;
        border-radius: var(--borderRadius-small, 4px);
        border: 1px solid var(--divider, rgba(0, 0, 0, 0.12));
  
        .toggleButton {
          display: flex;
          width: 2.5rem;
          height: 2.5rem;
          padding: 0.5rem;
          justify-content: center;
          align-items: center;
          border-right: 1px solid var(--divider, rgba(0, 0, 0, 0.12));
          background: var(--action-hover, rgba(0, 0, 0, 0.04));
        }
      }
      @media screen and (max-width: 880px) {
        width: 100%;
      }
    }
  }

  .frame-202 {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: -171px;
    align-self: stretch;
    .right-frame {
      display: flex;
      padding: 62px 0px;
      flex-direction: column;
      align-items: center;
      gap: 32px;
      align-self: stretch;
      .frame-201 {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 18px;
        align-self: stretch;
      }
    }
  }
  .frame-218 {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 85px;
    align-self: stretch;

    @media (max-width: 768px) {
       margin-top: 12rem;
    }

    .frame-203 {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 8px;
    }
    .frame-213 {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 32px;
      align-self: stretch;
      @media (max-width: 768px) {
        flex-direction: column;
      }
      .frame-209,
      .frame-215,
      .frame-218 {
        display: flex;
        width: 375px;
        padding: 28px 24px;
        flex-direction: column;
        align-items: flex-start;
        gap: 18px;
        border-radius: 16px;
        background: var(--primary-dark, #093535);
        .frame-235 {
          display: flex;
          align-items: center;
          gap: 18px;
          align-self: stretch;
        }
      }
    }
  }
  .frame-246 {
    display: flex;
    width: 1127px;
    flex-direction: column;
    align-items: flex-start;
    @media (max-width: 768px) {
       width: 90%;
    }
    gap: 32px;
    .frame-246-1 {
      display: flex;
      justify-content: space-between;
      align-items: center;
      align-self: stretch;
      .frame-247 {
        display: flex;
        align-items: center;
        gap: 24px;
        .text1 {
          color: var(--primary-contrastText, #fff) !important;
          font-feature-settings: "liga" off, "clig" off !important;
          font-family: var(--fontFamily, "IBM Plex Sans") !important;
          font-size: 60px !important;
          font-style: normal !important;
          font-weight: 300 !important;
          line-height: 120% !important; /* 72px */
          letter-spacing: -0.5px !important;

          .text2 {
            color: var(--primary-contrastText, #fff) !important;
            font-feature-settings: "liga" off, "clig" off !important;
            font-family: var(--fontFamily, "IBM Plex Sans") !important;
            font-size: var(--font-size-375-rem, 60px) !important;
            font-style: normal !important;
            font-weight: var(--fontWeightLight, 300) !important;
            line-height: 120% !important;
            letter-spacing: -0.5px !important;
          }
          .highlight {
            color: var(--green-A200, #69f0ae) !important;
            font-feature-settings: "liga" off, "clig" off !important;
            font-family: var(--fontFamily, "IBM Plex Sans") !important;
            font-size: var(--font-size-375-rem, 60px) !important;
            font-style: normal !important;
            font-weight: var(--fontWeightLight, 300) !important;
            line-height: 120% !important;
            letter-spacing: -0.5px !important;
          }
        }
      }
      .description {
        color: var(--primary-contrastText, #fff) !important;
        font-feature-settings: "liga" off, "clig" off !important;
        font-family: "IBM Plex Sans" !important;
        font-size: 16px !important;
        font-style: normal !important;
        font-weight: var(--fontWeightRegular, 400) !important;
        line-height: 133.4% !important; /* 21.344px */
        width: 456px !important;
      }
    }
  }
  .frame-237 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    @media (max-width: 768px) {
      width: 90%;
   }
   
    .frame-227 {
      display: flex;
      width: 1440px;
      padding: 46px 127px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 25px;
      border-top: 1px solid var(--primary-contrastText, #fff);
      border-bottom: 1px solid var(--primary-contrastText, #fff);
      background: #135353;
      @media (max-width: 768px) {
        width: 90%;
     }
      .text {
        color: var(--primary-contrastText, #fff);
        font-feature-settings: "liga" off, "clig" off;
        font-family: var(--fontFamily, "IBM Plex Sans");
        font-size: 32px;
        font-style: normal;
        font-weight: var(--fontWeightRegular, 400);
        line-height: 116.7%; /* 37.344px */
      }
    }
    .frame-229 {
      display: flex;
      width: 1440px;
      padding: 24px 56px;
      justify-content: space-between;
      align-items: center;
      background: var(--primary-dark, #093535);
      @media (max-width: 768px) {
        width: 90%;
        flex-direction: column;
     }
      .frame-231 {
        display: flex;
        width: 714px;
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;
        flex-shrink: 0;
        @media (max-width: 768px) {
          width: 90%;
          align-items: center;
       }
        
      }
      .frame-230 {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-end;
        gap: 12px;

        .frame-228 {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 23px; // Ensures spacing between links and separator
        }
      }
    }
  }
}
