@use "../../sass/util" as u;
@use "../../sass/global" as g;

.homepage {
  display: flex;
  align-items: flex-start;
  min-height: 100vh;
  background: var(--surface-secondary, #f6f8f8);

  .container-body {
    display: flex;
    flex-direction: column;
    flex: 1 0 0;
  }

    .sidebar-drawer {
      height: 100vh;
      position: fixed;
      display: flex;
      width: 15rem;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      flex-shrink: 0;
      align-self: stretch;
      border-right: 0.0625rem solid rgba(255, 255, 255, 0.2);
      background: var(--massic-800, #093535);

      .sidebar-header {
        // overflow-y: auto !important;
        // overflow-x: hidden;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 1rem;
        align-self: stretch;
        .sidebar-title {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 1rem;
          align-self: stretch;

          .title {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            align-self: stretch;
            .logo {
              display: flex;
              height: 4rem;
              padding: 1rem 0.75rem 1rem 0.75rem;
              flex-direction: column;
              justify-content: center;
              align-items: flex-start;
              gap: 0.5rem;
              align-self: stretch;
            }
          }
          .MuiButton-sizeMedium.MuiButton-containedPrimary:not(.Mui-disabled):not(
              .Mui-focusVisible
            ):not(:hover) {
            background: transparent !important;
          }
        }

        .sidebar-body {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 0.5rem;
          align-self: stretch;
          &-label {
            display: flex;
            padding: 0  0.75rem;
            align-items: center;
            gap: 0.5rem;
            align-self: stretch;
            
            &-name {
              color: #fff;
              text-align: center;
              font-feature-settings: "liga" off, "clig" off;
              opacity: 0.38;
            }
          }
          .MuiButton-sizeMedium.MuiButton-containedPrimary:not(.Mui-disabled):not(
              .Mui-focusVisible
            ):not(:hover) {
            background: transparent !important;
          }
        }
      }
      .line {
        width: 14.5rem;
        height: 0.0625rem;
        opacity: 0.2;
        background: var(--Schemes-On-Secondary, #fff);
      }

      .sidebar-footer {
        display: flex;
        padding-bottom: 1rem;
        flex-direction: column;
        align-items: flex-start;
        gap: 1rem;
        align-self: stretch;
        column-gap: u.rem(16);
        .company-name {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 0.5rem;
          align-self: stretch;
          .company-name-label {
            display: flex;
            padding: 0.5rem 1rem;
            align-items: center;
            gap: 0.5rem;
            align-self: stretch;
            .sidebar-footer-title {
              color: #fff;
              text-align: center;
              font-feature-settings: "liga" off, "clig" off;
              opacity: 0.38;
            }
          }
          .settings-button {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 0.5rem;
            align-self: stretch;
          }
        }
        .version {
          display: flex;
          padding: 0.5rem 1rem;
          align-items: center;
          gap: 0.5rem;
          align-self: stretch;
          .sidebar-footer-version {
            color: #fff;
            text-align: center;
            opacity: 0.38;
          }
        }

        .MuiButton-sizeMedium.MuiButton-containedPrimary:not(.Mui-disabled):not(
            .Mui-focusVisible
          ):not(:hover) {
          background: transparent !important;
        }
      }


      /* Media query to collapse the sidebar on tablets and mobiles */
  /* Media query for tablets and mobiles */
  @media (max-width: 768px) {
    // position: absolute;
    // z-index: 999;
    // height: 100vh;
    // visibility: collapse;
    
  }
  }

  @media screen and (max-width: 900px) {
    display: block;
  }
}
