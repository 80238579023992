// Import global styles and utilities
@use '../../../sass/global' as g;
@use '../../../sass/util' as u;

.userform-container {
  max-width: 500px;
  margin: 0 auto;
  padding: u.rem(20);
  background: #f7f7f7;
  border-radius: u.rem(8);

  // Title styling
  .usertitle {
    font-weight: 500;
    font-size: u.rem(18);
    margin-bottom: u.rem(24);
    color: g.$primary;
  }

  .ant-form {
    display: flex;
    flex-direction: column;
    row-gap: u.rem(6);

    .ant-form-item {
      margin-bottom: u.rem(4);

      .ant-form-item-label {
        display: block;
        margin-bottom: u.rem(4);

        label {
          display: block;
        }
      }

      .ant-form-item-control {
        width: 100%;

        .ant-input,
        .ant-select-selector {
          width: 100%;
        }

         .ant-input[disabled] {
          color: #333; 
          background-color: #eaeaea;
        }

        .ant-select-disabled .ant-select-selector {
          color: #333 !important; 
          background-color: #eaeaea !important;
        }
      }

      .error-message {
        color: red;
        font-size: u.rem(14);
      }

      .ant-btn {
        margin: u.rem(5) 0;
        width: 100%;
        width: auto;
      }
    }
  }
}

// Responsive styles
@include u.breakpoint-down(medium) {
  .userform-container {
    padding: u.rem(10);

    .usertitle {
      font-size: u.rem(16);
      margin-bottom: u.rem(20);
    }

    .ant-form {
      row-gap: u.rem(6);

      .ant-form-item {
        margin-bottom: u.rem(3);

        .ant-form-item-label {
          margin-bottom: u.rem(3);

          label {
            font-size: u.rem(14);
          }
        }

        .ant-form-item-control {
          .ant-input,
          .ant-select-selector {
            font-size: u.rem(14);
          }
        }

        .error-message {
          font-size: u.rem(12);
        }
      }
    }
  }
}
