@use '../../../sass/global' as g;
@use '../../../sass/util' as u;

.container {
  padding: u.rem(20);
  background-color: white;
  width: 100%
}

.back-button{
  border: none;
  margin: u.rem(5);
  font-size: u.rem(18);
}

.tabs {
  .ant-tabs-nav {
    margin-bottom: u.rem(4);
  }

  .ant-tabs-tab {
    font-size: u.rem(16);
    font-weight: 600;
  }

  .ant-tabs-tabpane {
    padding: 10px;
    background-color: white;
  }
}

.webiste{
  display: flex;
  justify-content: flex-start;
  margin-bottom: u.rem(5);
  align-items: center;
  font-weight: 600;
  font-size: 16px;
  color:black;
  
}

.statContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: u.rem(5);
  background-color: white;

  .counts {
    display: flex;
    gap: 1rem;

    .statItem {
      display: flex;
      align-items: center;

      .icon {
        font-size: u.rem(24);
        margin-right: u.rem(8);
      }
    }
  }

  .select {
    width: u.rem(200);
  }
}

.chartContainer {
  display: grid;
  padding: u.rem(16);
  grid-template-columns: repeat(1, 1fr);

  @include u.breakpoint-down(small){
    grid-template-columns: repeat(1, 1fr);
  }
}

.table-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: u.rem(16);
}

@include u.breakpoint-down(small) {
  .table-container {
    grid-template-columns: repeat(1, 1fr);
    row-gap: u.rem(16);
  }
}

