@use './functions' as f;

@mixin primaryBtn($bgcolor, $color, $borderColor){
  padding: f.rem(10) f.rem(30);
  background-color: $bgcolor;
  color: $color;
  border: 1px solid $borderColor;
  border-radius: f.rem(4);
  cursor: pointer;
  transition: 0.3s all ease-in;
  font-size: f.rem(16);
  font-weight: 500;
}
@mixin boxShadow{
  box-shadow: 1px 1px 4px 0px rgba(113, 140, 155, 0.15), -1px -1px 4px 0px rgba(113, 140, 155, 0.15);
}