@use '../../sass/global/colors' as *;


.MuiPaginationItem-root {
  &.MuiPaginationItem-root {
    color: $black;

    &.Mui-selected {
      background-color: $sidebar-bg-color;
      color: $white;
    }

    &:hover {
        background-color: $sidebar-bg-color;
        color: $white;
    }

    &.Mui-selected:hover {
      background-color: $sidebar-bg-color;
      color: $white;
    }
  }
}
