.reviews-container {
  width: 100%;
  padding: 24px 4.5rem;
  display: flex;
  flex-direction: column;

  .reviews-tabs {
    border-bottom: 1px solid #e0e0e0;
    margin-top: 1rem;
    flex-grow: 1;
    .tab {
      font-size: 14px;
      font-weight: var(--fontWeightMedium, 500);
      text-transform: capitalize;
    }
  }

  .review-tab-content {
    border-radius: var(--borderRadius, 8px);
    background: var(--surface-secondary, #f6f8f8);
    .load-more-button-container{
      margin-top: 1rem;
    }
  }

  @media screen and (max-width: 880px) {
    padding: 24px 1rem;
  }
}

