@use "../../sass/global" as g;
@use "../../sass/util" as u;

.date-ga4 {
  display: flex;
  padding: u.rem(10) u.rem(10) 0 0;
  justify-content: flex-end;
  align-items: center;
  @include u.breakpoint-down(small) {
    justify-content: flex-start;
  }
}

.charts-container-ga4 {
  display: grid;
  gap: u.rem(16);
  padding: u.rem(10);
  grid-template-columns: repeat(2, 1fr);

  @include u.breakpoint-down(small) {
    grid-template-columns: repeat(1, 1fr);
  }
}
