// Import global styles and utilities
@use '../../../sass/global' as g;
@use '../../../sass/util' as u;

// Main container styles
.form-container {
  max-width: 600px;
  margin: 0 auto;
  padding: u.rem(20);
  background: #f7f7f7;
  border-radius: u.rem(8);

  // Title styling
  .title {
    font-weight: 500;
    font-size: u.rem(18);
    margin-bottom: u.rem(24);
    color: g.$primary;
  }

  .form-toggle {
    display: flex;
    justify-content: center;
    margin-bottom: u.rem(20);

    .link-button {
      background: none;
      border: none;
      padding: 0;
      margin: 0 u.rem(10);
      color: g.$primary;
      font-weight: bold;
      cursor: pointer;
      text-decoration: none;
      font-size: u.rem(16);

      &.active {
        color: g.$primary;
        border-bottom: u.rem(2) solid g.$primary;
      }

      &:hover {
        color: g.$primary;
      }
    }
  }

  // Form content styles
  .form-content {
    display: flex;
    flex-direction: column;
    row-gap: u.rem(12); 

    .form-item {
      margin-bottom: u.rem(8); 

      label {
        display: block;
        margin-bottom: u.rem(8);
      }

      .ant-input,
      .ant-select-selector,
      .ant-input-textarea {
        width: 100%;
      }

      .ant-input-textarea {
        resize: vertical;
      }
    }

    .error-message {
      color: red;
      font-size: u.rem(14);
    }

    .button-group {
      display: flex;
      justify-content: space-between;
      margin-top: u.rem(20);

      .ant-btn {
        width: 48%;
      }

      .save-button{
        background-color: g.$created;
        color: g.$white;
        &:hover{
          background-color: g.$white;
          color: g.$black;
          border: 1px solid g.$black;
        }
      }

      .confirm-button{
        background-color: g.$success;
        color: g.$white;
        &:disabled{
          background-color: #f7f7f7;
          color: black;
        }
        &:hover{
          background-color: g.$white;
          color: g.$black;
          border: 1px solid g.$black;
        }
      }
    
    }
  }

  // Responsive styles
  @include u.breakpoint-down(small) {
    .form-container {
      padding: u.rem(10);
    }
  }

  @include u.breakpoint-down(xsmall) {
    .form-container {
      padding: u.rem(5);

      .link-button {
        font-size: u.rem(12);
      }

      .form-content {
        row-gap: u.rem(8); 
      }
    }
  }
}
