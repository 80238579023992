@use '../../sass/global' as g;
@use '../../sass/util' as u;

.analytics-chart-card {
    display: flex;
    flex-direction: column;
    .title{
        width: 100%;
        text-align: center;
        margin: u.rem(10);
        color: g.$primary;
        font-size: u.rem(20);
    }
    .stats-row {
      margin-bottom: u.rem(16);
    }
  
    .metric-button {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: max-content;
      padding: u.rem(5);
      width: 100%;
      text-align: center;
      border-radius: u.rem(8);
  
      .metric-title {
        font-size: u.rem(16);
        font-weight: 600;
        margin-bottom: u.rem(8);
        display: flex;
        column-gap: u.rem(4);
        align-items: center;
        word-break: break-word; 
        overflow-wrap: break-word; 
        white-space: normal; 
        padding: 0 8px;
        max-width: 150px;
      }
  
      .metric-value {
        font-size: u.rem(24);
        font-weight: 700;
        margin-bottom: u.rem(8);
      }
  
      .metric-percentage {
        display: flex;
        align-items: center;
        justify-content: center;
  
        .ant-typography {
          font-size: u.rem(14);
          margin-right: u.rem(4);
        }
  
        .anticon {
          font-size: u.rem(16);
        }
      }
      
    }
  
    .view-report-button {
      display: flex;
      row-gap: 0.3rem;
      width: 100%;
      justify-content: flex-end;
      align-items: center;
      Button{
        border: none;
        font-size: u.rem(16);
      }
    }

  }
  