@use '../../sass/global' as g;
@use '../../sass/util' as u;

.chart-container {
  border: 1px solid g.$chartContainer;
  border-radius: u.rem(4);
  background-color: g.$chartBackground;
  padding: u.rem(20);
  margin-bottom: u.rem(20);

  h2 {
    text-align: center;
    margin-bottom: u.rem(20);
  }

  .chart {
    width: 100%;
    height: u.rem(400);
  }
}

.chart-container {
  width: 100%;
  // min-width: 600px; /* Ensure minimum width */
  min-height: 300px;
  .recharts-wrapper {
    min-width: 100%;
    min-height: 100%;
  }
}


