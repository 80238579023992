@use '../../../sass/global' as g;
@use '../../../sass/util' as u;

.view-page {
  padding: u.rem(20);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @include u.breakpoint-down(medium) {
    padding: u.rem(10);
  }

  h2 {
    text-align: center;
    margin-bottom: u.rem(20);
  }

  .ant-table-wrapper {
    width: 100%;
    overflow-x: auto;
    max-width: u.rem(1000);

    .ant-table {
      max-width: u.rem(1000);
      width: 100%;
      overflow-x: auto;

      th, td {
        text-align: center;
        font-size: u.rem(16);
      }


      @include u.breakpoint-down(medium) {
        th, td {
          padding: u.rem(6);
          font-size: u.rem(14);
        }
      }
    }
  }

  .action-buttons {
    display: flex;
    flex-direction: row;
    button {
      font-size: u.rem(14);
      margin-bottom: u.rem(10);
      padding: u.rem(10);
      &:last-child {
        margin-bottom: 0;
      }
    }

    @include u.breakpoint-down(medium) {
      flex-direction: column;
      button {
        font-size: u.rem(12);  
        margin-bottom: u.rem(8);
        padding: u.rem(10); 
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  .ant-btn {
    margin: u.rem(5) 0;
    width: 100%;
    width: auto;
  }
}
